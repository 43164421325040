// calls.js

import React from 'react' //, { useState, useEffect }

// react admin
import {
  List,
  ReferenceManyField,
  ReferenceField,
  SelectField,
  Datagrid,
  TextField,
  NumberField,
  DateField,
  Filter,
  SelectInput,
  TextInput,
  Edit,
  SimpleForm,
  TopToolbar,
  UrlField,
} from 'react-admin'

// generic components
import {
  ShowOriginField,
  ShowDurationField,
  ListTitle,
  ListPagination,
  EditTitle,
  ContainerBox,
  LeftBox,
  RightBox,
  MarginLabeledLeft,
  MarginLabeledRight,
  DateInput,
  equalToDay,
} from 'kevo-react-admin'

import RecordingFieldCustom from '../Components/RecordingFieldCustom.jsx'

/*
create type callstatuschoices as enum ('queued','initiated','ringing','in-progress','completed','busy','no-answer','canceled','failed');

create table calls(
id serial not null primary key,
sid varchar(255) not null unique,
phone varchar(255) not null,
status callstatuschoices not null default 'initiated',
created timestamp not null default now(),
updated timestamp
);
*/

//const resource = 'calls'
const resourceName = 'llamada'
const resourceNamePlural = 'llamadas'
//const masculine = false //${masculine ? "un" : "una"}
const mainField = 'sid'

// list filter requires an index in filterable column!
const ListFilters = () => (
  <Filter>
    <TextInput label={`Buscar SID...`} source={`${mainField}`} alwaysOn />
    <TextInput label={`Buscar número...`} source="phone" alwaysOn />
    <DateInput source="created" label="Filtrar fecha..." alwaysOn {...equalToDay('created')} />
    <SelectInput
      size="small"
      sx={{ marginBottom: '0px', minWidth: '226px' }}
      label={`Filtrar estado...`}
      source="status"
      alwaysOn
      choices={[
        /*{ id: 'QUEUED', name: 'Encolada' },*/
        /*{ id: 'INITIATED', name: 'Iniciada' },*/
        /*{ id: 'RINGING', name: 'Llamando' },*/
        { id: 'IN_PROGRESS', name: 'En curso' },
        { id: 'COMPLETED', name: 'Completada' },
        { id: 'BUSY', name: 'Línea ocupada' },
        { id: 'NO_ANSWER', name: 'Sin respuesta' },
        { id: 'CANCELED', name: 'Cancelada' },
        { id: 'FAILED', name: 'Fallida' },
      ]}
    />
  </Filter>
)

const ListActions = () => {
  return <TopToolbar></TopToolbar>
}

export const CallList = () => {
  return (
    <List
      title={<ListTitle resourceNamePlural={resourceNamePlural} />}
      filters={<ListFilters />}
      empty={false}
      pagination={<ListPagination />}
      perPage={25}
      actions={<ListActions />}
      sort={{ field: 'id', order: 'DESC' }}
    >
      <Datagrid rowClick={'edit'} bulkActionButtons={false}>
        <NumberField source="id" label="ID" textAlign="left" />
        <TextField source="sid" label="SID" textAlign="left" />
        <TextField source="phone" label="Telefono" textAlign="left" />
        <SelectField
          source="status"
          label="Estado"
          choices={[
            { id: 'IN_PROGRESS', name: 'En curso' },
            { id: 'COMPLETED', name: 'Completada' },
            { id: 'BUSY', name: 'Línea ocupada' },
            { id: 'NO_ANSWER', name: 'Sin respuesta' },
            { id: 'CANCELED', name: 'Cancelada' },
            { id: 'FAILED', name: 'Fallida' },
          ]}
        />

        <ShowDurationField />
        <DateField
          source="created"
          label="Creado"
          textAlign="left"
          locales="es"
          showTime={true}
          options={{
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            timeZone: 'Europe/Madrid',
          }}
        />
        <DateField
          source="updated"
          label="Actualizado"
          textAlign="left"
          emptyText="Nunca"
          locales="es"
          showTime={true}
          options={{
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            timeZone: 'Europe/Madrid',
          }}
        />
      </Datagrid>
    </List>
  )
}

export const CallEdit = () => {
  return (
    <Edit title={<EditTitle resourceName={resourceName} mainField={mainField} />}>
      <SimpleForm>
        <ContainerBox>
          <LeftBox>
            <MarginLabeledLeft label="ID">
              <NumberField source="id" textAlign="left" />
            </MarginLabeledLeft>

            <MarginLabeledLeft label="SID">
              <TextField source="sid" textAlign="left" />
            </MarginLabeledLeft>

            <MarginLabeledLeft label="Telefono">
              <TextField source="phone" textAlign="left" />
            </MarginLabeledLeft>

            <MarginLabeledLeft label="Duración">
              <ShowDurationField />
            </MarginLabeledLeft>

            <MarginLabeledLeft label="Estado">
              <SelectField
                source="status"
                choices={[
                  { id: 'IN_PROGRESS', name: 'En curso' },
                  { id: 'COMPLETED', name: 'Completada' },
                  { id: 'BUSY', name: 'Línea ocupada' },
                  { id: 'NO_ANSWER', name: 'Sin respuesta' },
                  { id: 'CANCELED', name: 'Cancelada' },
                  { id: 'FAILED', name: 'Fallida' },
                ]}
              />
            </MarginLabeledLeft>
          </LeftBox>
          <RightBox>
            <MarginLabeledRight label="Creado">
              <DateField
                source="created"
                locales="es"
                showTime={true}
                options={{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                  timeZone: 'Europe/Madrid',
                }}
                textAlign="left"
              />
            </MarginLabeledRight>
            <MarginLabeledRight label="Actualizado">
              <DateField
                source="updated"
                emptyText="Nunca"
                locales="es"
                showTime={true}
                options={{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                  timeZone: 'Europe/Madrid',
                }}
                textAlign="left"
              />
            </MarginLabeledRight>
          </RightBox>
        </ContainerBox>
        <ContainerBox>
          <MarginLabeledLeft label="Grabación de la llamada">
            <RecordingFieldCustom />
          </MarginLabeledLeft>
        </ContainerBox>
        <ReferenceManyField
          label="Transcripciones de la llamada"
          reference="transcriptions"
          target="transcriptioncall"
          sort={{ field: 'id', order: 'ASC' }}
          perPage={1000000}
          fullWidth
        >
          <Datagrid bulkActionButtons={false} sx={{ marginTop: '16px', width: '100%' }}>
            {false && (
              <ReferenceField source="id" label="ID" reference="transcriptions">
                <TextField source="id" />
              </ReferenceField>
            )}
            <ShowOriginField />
            <TextField multiline="true" source="transcription" label="Transcripción" />
            <DateField
              source="created"
              label="Creado"
              textAlign="left"
              locales="es"
              showTime={true}
              options={{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                timeZone: 'Europe/Madrid',
              }}
            />
          </Datagrid>
        </ReferenceManyField>
        <ReferenceManyField
          label="Eventos de la llamada"
          reference="events"
          rowClick="edit"
          target="eventcall"
          sort={{ field: 'id', order: 'ASC' }}
          perPage={1000000}
          fullWidth
        >
          <Datagrid bulkActionButtons={false} sx={{ marginTop: '16px', width: '100%' }}>
            <ReferenceField source="id" label="ID" reference="events">
              <TextField source="id" />
            </ReferenceField>
            <SelectField
              source="type"
              label="Evento"
              choices={[
                { id: 'CALL_HANGUP_BEFORE_START', name: 'Cuelga al inicio' },
                { id: 'CALL_ROUTED_TO_DESTINATION', name: 'Llamada enrutada' },
                { id: 'CALL_ROUTE_NOT_SUCCESSFUL', name: 'Enrutamiento fallido' },
                { id: 'CALL_ROUTE_SUCCESSFUL', name: 'Enrutamiento exitoso' },
                { id: 'CALL_EMAIL_SENT_OK', name: 'Email enviado' },
              ]}
            />
            <TextField multiline="true" source="description" label="Descripción" />
            <DateField
              source="created"
              label="Creado"
              textAlign="left"
              locales="es"
              showTime={true}
              options={{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                timeZone: 'Europe/Madrid',
              }}
            />
          </Datagrid>
        </ReferenceManyField>
      </SimpleForm>
    </Edit>
  )
}
