// names.js

import React from 'react'

// react admin
import {
  List,
  ReferenceField,
  Toolbar,
  BooleanField,
  BooleanInput,
  FunctionField,
  SaveButton,
  DeleteButton,
  ReferenceInput,
  Datagrid,
  TextField,
  NumberField,
  DateField,
  Filter,
  Create,
  SelectInput,
  TextInput,
  Edit,
  SimpleForm,
  TopToolbar,
  useNotify,
  usePermissions,
  Loading,
} from 'react-admin'

// custom resource components generic to all resources
import {
  ListTitle,
  ListPagination,
  EditTitle,
  CreateTitle,
  ContainerBox,
  LeftBox,
  RightBox,
  MarginLabeledLeft,
  MarginLabeledRight,
} from 'kevo-react-admin'

// icon for this resource
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd'
export const NameIcon = AssignmentIndIcon

/*
create table names(
id serial not null primary key,
name varchar(255) not null unique,
namecustomer int references customers(id) on delete cascade not null,
created timestamptz not null default now(),
updated timestamptz
);

insert into names(name,namecustomer) values('kevo technologies',1);
*/

const resource = 'names'
const resourceName = 'nombre de cliente de Proactivanet'
const resourceNamePlural = 'nombres de clientes de Proactivanet'
const masculine = true //${masculine ? "un" : "una"}
const mainField = 'name'

// list filter requires an index in filterable column!
const ListFilters = ({ permissions, ...props }) => (
  <Filter>
    <TextInput label="Buscar nombre..." source="name" alwaysOn />
  </Filter>
)

export const NameOrigin = (record) => {
  if (record.proactivanet) {
    return 'Proactivanet'
  } else {
    return 'K35 Soporte'
  }
}

const ListActions = () => {
  return <TopToolbar></TopToolbar>
}

export const NameList = ({ permissions, ...props }) => (
  <List
    title={<ListTitle resourceNamePlural={resourceNamePlural} />}
    filters={permissions && <ListFilters permissions={permissions} />}
    pagination={<ListPagination />}
    perPage={25}
    actions={<ListActions />}
    empty={false}
    {...props}
  >
    <Datagrid rowClick="edit">
      <NumberField source="id" label="ID" textAlign="left" />
      <TextField source="name" label="Nombre" textAlign="left" />
      <ReferenceField source="namecustomer" label="Cliente de Proactivanet" reference="customers">
        <TextField source="name" />
      </ReferenceField>
      <BooleanField source="active" label="Activo" />
      <FunctionField render={NameOrigin} label="Orígen" />
      <DateField
        source="created"
        label="Creado"
        textAlign="left"
        locales="es"
        showTime={true}
        options={{
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          timeZone: 'Europe/Madrid',
        }}
      />
      <DateField
        source="updated"
        label="Actualizado"
        textAlign="left"
        emptyText="Nunca"
        locales="es"
        showTime={true}
        options={{
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          timeZone: 'Europe/Madrid',
        }}
      />
    </Datagrid>
  </List>
)

// redirects to the parent customer if we came from there
const redirect = () => {
  if (window.location.search.includes('?source=')) {
    window.location.href = `/customers/${
      JSON.parse(window.location.search.replace('?source=', '').replaceAll('%22', '"')).namecustomer
    }`
  } else {
    window.location.href = 'edit'
  }
}

// custom button to make all lowercase before sending (prevents duplicates)
const NameEditToolbar = () => (
  <Toolbar>
    <SaveButton
      label="Guardar"
      transform={(data) => {
        //console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>')
        //console.log(data)
        return { ...data, name: data.name.toLowerCase(), proactivanet: false }
      }}
      mutationOptions={{
        onSuccess: () => {
          notify('Nombre adicional actualizado exitosamente', { type: 'success' })
          redirect()
        },
      }}
      type="button"
    />
    <DeleteButton
      style={{ marginLeft: 'auto' }}
      mutationOptions={{
        onSuccess: () => {
          notify('Nombre adicional eliminado exitosamente', { type: 'success' })
          redirect()
        },
        undoable: false,
      }}
      type="button"
    />
  </Toolbar>
)

export const NameEdit = () => {
  const { isLoading, permissions } = usePermissions()
  if (isLoading) {
    return <Loading loadingPrimary="Cargando" loadingSecondary="" />
  }
  return (
    <Edit title={<EditTitle resourceName={resourceName} mainField={mainField} />} mutationMode="pessimistic">
      <SimpleForm toolbar={<NameEditToolbar />}>
        <ContainerBox>
          <LeftBox>
            {['ADMIN'].includes(permissions) && (
              <MarginLabeledLeft label="ID">
                <NumberField source="id" textAlign="left" sx={{ display: 'inline-flex', marginInlineEnd: '2rem' }} />
              </MarginLabeledLeft>
            )}
            <MarginLabeledLeft label="Cliente de Proactivanet">
              <ReferenceField
                source="namecustomer"
                reference="customers"
                sx={{ display: 'inline-flex', marginInlineEnd: '2rem' }}
              >
                <TextField source="name" />
              </ReferenceField>
            </MarginLabeledLeft>
            <MarginLabeledLeft label="Orígen">
              <FunctionField render={NameOrigin} sx={{ display: 'inline-flex', marginInlineEnd: '2rem' }} />
            </MarginLabeledLeft>
          </LeftBox>
          <RightBox>
            <MarginLabeledRight label="Creado">
              <DateField
                source="created"
                textAlign="left"
                locales="es"
                showTime={true}
                options={{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                  timeZone: 'Europe/Madrid',
                }}
                sx={{ display: 'inline-flex', marginInlineEnd: '2rem', float: 'right' }}
              />
            </MarginLabeledRight>
            <MarginLabeledRight label="Actualizado">
              <DateField
                source="updated"
                textAlign="left"
                emptyText="Nunca"
                locales="es"
                showTime={true}
                options={{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                  timeZone: 'Europe/Madrid',
                }}
                sx={{ display: 'inline-flex', marginInlineEnd: '2rem', float: 'right' }}
              />
            </MarginLabeledRight>
          </RightBox>
        </ContainerBox>
        <ContainerBox>
          <LeftBox>
            <MarginLabeledLeft label="Nombre">
              <TextInput
                source="name"
                textAlign="left"
                fullWidth={true}
                helperText="Nombre adicional para este cliente de Proactivanet (tiene que ser único) - ¡poner minúsculas todo para evitar duplicados!"
              />
            </MarginLabeledLeft>
          </LeftBox>
          <RightBox>
            <MarginLabeledRight label="Activo">
              <BooleanInput
                source="active"
                fullWidth={true}
                helperText="Activa/desactiva este nombre"
                sx={{ display: 'inline-flex', marginInlineEnd: '2rem' }}
              />
            </MarginLabeledRight>
          </RightBox>
        </ContainerBox>
      </SimpleForm>
    </Edit>
  )
}

const validateNameCreation = (values) => {
  //console.log(values)
  const errors = {}
  if (!values.namecustomer) {
    errors.namecustomer = 'Es necesario indicar el cliente de Proactivanet'
  }
  if (!values.name) {
    errors.name = 'Es necesario introducir un nombre adicional para el cliente de Proactivanet'
  }
  return errors
}

// custom button to make all lowercase before sending (prevents duplicates)
const NameCreateToolbar = () => {
  const notify = useNotify()
  return (
    <Toolbar>
      <SaveButton
        label="Crear nombre adicional"
        transform={(data) => {
          //console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>')
          //console.log(data)
          return { ...data, name: data.name.toLowerCase(), proactivanet: false }
        }}
        mutationOptions={{
          onSuccess: () => {
            notify('Nombre adicional creado exitosamente', { type: 'success' })
            redirect()
          },
        }}
        type="button"
      />
    </Toolbar>
  )
}

export const NameCreate = ({ permissions, ...props }) => (
  <Create title={<CreateTitle masculine={masculine} resourceName={resourceName} />} {...props}>
    <SimpleForm validate={validateNameCreation} toolbar={<NameCreateToolbar />}>
      <ReferenceInput
        source="namecustomer"
        label="Cliente de Proactivanet"
        reference="customers"
        sort={{ field: 'name', order: 'ASC' }}
        perPage={100000000}
        fullWidth={true}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput
        source="name"
        label="Nombre"
        helperText="Nombre adicional para este cliente de Proactivanet (tiene que ser único) - ¡poner minúsculas todo para evitar duplicados!"
        textAlign="left"
        fullWidth={true}
      />
    </SimpleForm>
  </Create>
)
