// users.js

import React from 'react'

// react admin
import {
  List,
  PasswordInput,
  SelectField,
  Datagrid,
  TextField,
  EmailField,
  NumberField,
  DateField,
  Filter,
  Create,
  SelectInput,
  TextInput,
  BooleanInput,
  Edit,
  SimpleForm,
  BooleanField,
  usePermissions,
  Loading,
  TopToolbar,
} from 'react-admin'

// generic components
import { ListTitle, ListPagination, EditTitle, CreateTitle, ShowStarsField } from 'kevo-react-admin'

/*
create type usertypechoices as enum ('client','manager','admin');

create table users(
id serial not null primary key,
uuid uuid not null unique default gen_random_uuid(),
type usertypechoices not null default 'client',
username varchar(255) not null unique,
password varchar(255) not null,
email varchar(255),
active boolean not null default true,
lastlogin timestamp,
recoverytoken text unique,
recoverytokenvaliduntil timestamptz,
created timestamp not null default now(),
updated timestamp
);

insert into users (type, username, password, email) values ('admin', 'kevo', '1234', 'jarzelus@kevo.ai');
*/

//const resource = 'users'
const resourceName = 'usuario'
const resourceNamePlural = 'usuarios'
const masculine = true //${masculine ? "un" : "una"}
const mainField = 'username'

// list filter requires an index in filterable column!
const ListFilters = ({ permissions }) => (
  <Filter>
    <TextInput label={`Buscar ${resourceName}...`} source={`${mainField}`} alwaysOn />
    <TextInput label="Buscar email..." source="email" alwaysOn />
    <SelectInput
      size="small"
      sx={{ marginBottom: '0px', minWidth: '226px' }}
      label="Filtrar tipo..."
      source="type"
      alwaysOn
      choices={[
        { id: 'CLIENT', name: 'Cliente' },
        { id: 'MANAGER', name: 'Gestor' },
        ...(permissions === 'ADMIN' ? [{ id: 'ADMIN', name: 'Administrador' }] : []),
      ]}
    />
  </Filter>
)

const ListActions = () => {
  return <TopToolbar></TopToolbar>
}

export const UserList = (props) => {
  const { isLoading, permissions } = usePermissions()
  if (isLoading) {
    return <Loading />
  }
  return (
    <List
      title={<ListTitle resourceNamePlural={resourceNamePlural} />}
      empty={false}
      pagination={<ListPagination />}
      perPage={25}
      sort={{ field: 'id', order: 'DESC' }}
      actions={<ListActions />}
      filter={!isLoading && permissions !== 'ADMIN' ? { type: ['MANAGER', 'CLIENT'] } : null}
      filters={<ListFilters permissions={permissions} />}
    >
      <Datagrid rowClick="edit">
        <NumberField source="id" label="ID" textAlign="left" />
        <SelectField
          source="type"
          label="Tipo"
          choices={[
            { id: 'CLIENT', name: 'Cliente' },
            { id: 'MANAGER', name: 'Gestor' },
            { id: 'ADMIN', name: 'Administrador' },
          ]}
        />
        <BooleanField source="active" label="Activo" textAlign="left" />
        <DateField
          source="lastlogin"
          label="Último login"
          textAlign="left"
          emptyText="Nunca"
          locales="es"
          showTime={true}
          options={{
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            timeZone: 'Europe/Madrid',
          }}
        />
        <TextField source="username" label="Usuario" textAlign="left" />
        <EmailField source="email" label="Email" textAlign="left" />
        <DateField
          source="created"
          label="Creado"
          textAlign="left"
          locales="es"
          showTime={true}
          options={{
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            timeZone: 'Europe/Madrid',
          }}
        />
        <DateField
          source="updated"
          label="Actualizado"
          textAlign="left"
          emptyText="Nunca"
          locales="es"
          showTime={true}
          options={{
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            timeZone: 'Europe/Madrid',
          }}
        />
      </Datagrid>
    </List>
  )
}

export const UserEdit = (props) => {
  return (
    <Edit title={<EditTitle resourceName={resourceName} mainField={mainField} />}>
      <SimpleForm>
        <NumberField source="id" label="ID" textAlign="left" sx={{ display: 'inline-flex', marginInlineEnd: '2rem' }} />
        <TextField
          source="uuid"
          label="UUID"
          textAlign="left"
          sx={{ display: 'inline-flex', marginInlineEnd: '2rem' }}
        />
        <SelectField
          size="small"
          source="type"
          label="Tipo"
          sx={{ display: 'inline-flex', marginInlineEnd: '2rem' }}
          choices={[
            { id: 'CLIENT', name: 'Cliente' },
            { id: 'MANAGER', name: 'Gestor' },
            { id: 'ADMIN', name: 'Administrador' },
          ]}
        />
        <DateField
          source="lastlogin"
          label="Último login"
          emptyText="Nunca"
          locales="es"
          showTime={true}
          options={{
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            timeZone: 'Europe/Madrid',
          }}
          textAlign="left"
          sx={{ display: 'inline-flex', marginInlineEnd: '2rem' }}
        />
        <DateField
          source="created"
          label="Creado"
          locales="es"
          showTime={true}
          options={{
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            timeZone: 'Europe/Madrid',
          }}
          textAlign="left"
          sx={{ display: 'inline-flex', marginInlineEnd: '2rem' }}
        />
        <DateField
          source="updated"
          label="Actualizado"
          emptyText="Nunca"
          locales="es"
          showTime={true}
          options={{
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            timeZone: 'Europe/Madrid',
          }}
          textAlign="left"
          sx={{ display: 'inline-flex', marginInlineEnd: '2rem' }}
        />
        <BooleanInput
          source="active"
          label="Activo"
          helperText="Si no está activo no podrá iniciar sesión"
          textAlign="left"
          fullWidth={true}
        />
        <TextField source="username" label="Usuario" textAlign="left" />
        <PasswordInput
          source="password"
          label="Contraseña"
          inputProps={{ autoComplete: 'current-password' }}
          helperText="La contraseña se encriptará una vez cambiada"
          textAlign="left"
          fullWidth={true}
        />
        <ShowStarsField />
        <TextInput source="email" label="Email" textAlign="left" fullWidth={true} />
      </SimpleForm>
    </Edit>
  )
}

const validateUserCreation = (values) => {
  //console.log(values)
  const errors = {}
  if (!values.type) {
    errors.type = 'Es necesario indicar el tipo de usuario'
  }
  if (!values.username) {
    errors.username = 'Es necesario introducir un nombre de usuario'
  }
  if (!values.password) {
    errors.password = 'Es necesario introducir una contraseña'
  }
  if (!values.email) {
    errors.email = 'Es necesario introducir un email'
  }
  if (values.active === undefined) {
    errors.active = 'Es necesario indicar si el usuario está activo o no'
  }
  return errors
}

export const UserCreate = (props) => {
  const { isLoading, permissions } = usePermissions()
  if (isLoading) {
    return <Loading />
  }
  return (
    <Create title={<CreateTitle masculine={masculine} resourceName={resourceName} />}>
      <SimpleForm validate={validateUserCreation}>
        <SelectInput
          source="type"
          size="small"
          label="Tipo"
          helperText="No se debe cambiar el tipo de usuario una vez creado el registro"
          fullWidth={true}
          choices={[
            { id: 'CLIENT', name: 'Cliente' },
            { id: 'MANAGER', name: 'Gestor' },
            permissions === 'ADMIN' ? [{ id: 'ADMIN', name: 'Administrador' }] : [],
          ]}
        />
        <BooleanInput
          source="active"
          label="Activo"
          helperText="Si no está activo no podrá iniciar sesión"
          textAlign="left"
          fullWidth={true}
        />
        <TextInput
          source="username"
          label="Usuario"
          helperText="Tiene que ser único"
          textAlign="left"
          fullWidth={true}
        />
        <PasswordInput
          source="password"
          label="Contraseña"
          inputProps={{ autoComplete: 'current-password' }}
          helperText="La contraseña se encriptará una vez cambiada"
          textAlign="left"
          fullWidth={true}
        />
        <TextInput source="email" label="Email" textAlign="left" fullWidth={true} />
      </SimpleForm>
    </Create>
  )
}
