// transcriptions.js

import React from 'react'

// react admin
import {
  List,
  ReferenceField,
  Datagrid,
  TextField,
  NumberField,
  DateField,
  Filter,
  SelectInput,
  TextInput,
  Edit,
  SimpleForm,
  TopToolbar,
} from 'react-admin'

// generic components
import { ListTitle, ListPagination, EditTitle, ShowOriginField } from 'kevo-react-admin'

/*
create table transcriptions(
id serial not null primary key,
origin messageoriginchoices not null,
transcription varchar(1024) not null,
transcriptioncall integer references calls(id) on delete cascade not null,
created timestamp not null default now()
);
*/

//const resource = 'transcriptions'
const resourceName = 'transcripción'
const resourceNamePlural = 'transcripciones'
//const masculine = false //${masculine ? "un" : "una"}
const mainField = 'transcription'

const ListFilters = () => (
  <Filter>
    <TextInput label={`Buscar texto...`} source={`${mainField}`} alwaysOn />
    <SelectInput
      label={`Filtrar orígen...`}
      source="origin"
      alwaysOn
      choices={[
        { id: 'USER', name: 'Usuario' },
        { id: 'BOT', name: 'Bot' },
      ]}
    />
  </Filter>
)

const ListActions = () => {
  return <TopToolbar></TopToolbar>
}

export const TranscriptionList = () => {
  //const permissions = usePermissions()
  return (
    <List
      title={<ListTitle resourceNamePlural={resourceNamePlural} />}
      filters={<ListFilters />}
      empty={false}
      pagination={<ListPagination />}
      perPage={25}
      actions={<ListActions />}
      sort={{ field: 'id', order: 'DESC' }}
    >
      <Datagrid rowClick="edit">
        <NumberField source="id" label="ID" textAlign="left" />
        <ShowOriginField />
        <TextField source="transcription" label="Transcripción" textAlign="left" />
        <ReferenceField emptyText="No disponible" source="transcriptioncall" label="Llamada" reference="calls">
          <TextField source="sid" />
        </ReferenceField>
        <DateField
          source="created"
          label="Creado"
          textAlign="left"
          locales="es"
          showTime={true}
          options={{
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            timeZone: 'Europe/Madrid',
          }}
        />
      </Datagrid>
    </List>
  )
}

export const TranscriptionEdit = ({ ...props }) => {
  //const permissions = usePermissions()
  return (
    <Edit title={<EditTitle resourceName={resourceName} mainField={mainField} />}>
      <SimpleForm>
        <NumberField source="id" label="ID" textAlign="left" sx={{ display: 'inline-flex', marginInlineEnd: '2rem' }} />
        <ShowOriginField />
        <DateField
          source="created"
          label="Creado"
          textAlign="left"
          locales="es"
          showTime={true}
          options={{
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            timeZone: 'Europe/Madrid',
          }}
          sx={{ display: 'inline-flex', marginInlineEnd: '2rem' }}
        />
        <ReferenceField
          emptyText="No disponible"
          source="transcriptioncall"
          label="Llamada"
          reference="calls"
          sx={{ display: 'inline-flex', marginInlineEnd: '2rem', float: 'right' }}
        >
          <TextField source="sid" />
        </ReferenceField>
        <TextField source="transcription" label="Transcripción" textAlign="left" />
      </SimpleForm>
    </Edit>
  )
}
