// RecordingFieldCustom.jsx

import { UrlField, useRecordContext, usePermissions, Loading } from 'react-admin'

const RecordingFieldCustom = () => {
  const record = useRecordContext()
  const { isLoading, permissions } = usePermissions()
  console.log(record.recording)
  if (isLoading) {
    return <Loading />
  } else {
    return (
      <>
        {['ADMIN'].includes(permissions) && (
          <UrlField source="recording" emptyText="No se ha guardado grabación para esta llamada" />
        )}
        {record.recording !== null && (
          <audio src={record.recording} controls>
            No puedes reproducir el audio en este navegador
          </audio>
        )}
        {record.recording === null && <span>No se ha grabado esta llamada</span>}
      </>
    )
  }
}

export default RecordingFieldCustom
