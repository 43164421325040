// index.js

//import './wdyr.jsx'

import React from 'react'
import { createRoot } from 'react-dom/client'

// sentry error tracing
import * as Sentry from '@sentry/browser'

// datetime stuff
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'

// graphql client provider component
import { ClientProvider } from 'kevo-react-admin'

// the app
import App from './App.jsx'

// config for WDYR
// see https://github.com/welldone-software/why-did-you-render
/*if (import.meta.env.NODE_ENV === 'development' && false) {
  const whyDidYouRender = require('@welldone-software/why-did-you-render')
  whyDidYouRender(React, {
    trackAllPureComponents: true,
  })
}*/

if (import.meta.env.NODE_ENV === 'production' || import.meta.env.VITE_SENTRY_DNS !== 'no-sentry-in-development') {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DNS,
  })
}

const container = document.getElementById('root')
const root = createRoot(container)
root.render(
  <React.StrictMode>
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <ClientProvider App={App} />
    </LocalizationProvider>
  </React.StrictMode>
)
