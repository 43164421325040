// CatchAll.jsx

import { useNavigate } from 'react-router-dom'

// mui components
import CardContent from '@mui/material/CardContent'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

// mui icons
import BrokenImageIcon from '@mui/icons-material/BrokenImage'

// styles
import styled from '@emotion/styled'

const StyledCardContent = styled(CardContent)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #757575;
`
const StyledBrokenImageIcon = styled(BrokenImageIcon)`
  font-size: 6rem;
`

const CatchAll = () => {
  const navigate = useNavigate()

  return (
    <Card>
      <StyledCardContent>
        <StyledBrokenImageIcon />
        <Typography variant="h3" gutterBottom component="div">
          No encontrado
        </Typography>
        <Typography variant="subtitle1" gutterBottom component="div">
          El elemento no existe o se ha seguido un enlace incorrecto
        </Typography>
        <Button variant="contained" onClick={() => navigate(-1)}>
          Ir atrás
        </Button>
      </StyledCardContent>
    </Card>
  )
}

export default CatchAll
