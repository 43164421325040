// users.js

import React from 'react'

// react admin
import {
  List,
  ReferenceField,
  Datagrid,
  TextField,
  NumberField,
  DateField,
  Filter,
  TextInput,
  Edit,
  SimpleForm,
  TopToolbar,
} from 'react-admin'

// custom resource components generic to all resources
import { ListTitle, ListPagination, EditTitle } from 'kevo-react-admin'

// icon for this resource
import ContactsIcon from '@mui/icons-material/Contacts'
export const ContactIcon = ContactsIcon

/*
create table contacts(
id serial not null primary key,
name varchar(255) not null,
username varchar(255) not null unique,
email varchar(255) not null,
contactcustomer int references customers(id) on delete cascade not null,
created timestamptz not null default now(),
updated timestamptz
);

insert into contacts(name,username,email,contactcustomer) values('kevo','B75205468','administracion@grupok35.com',1);
*/

const resource = 'contacts'
const resourceName = 'contacto de cliente de Proactivanet'
const resourceNamePlural = 'contactos de clientes de Proactivanet'
//const masculine = true //${masculine ? "un" : "una"}
const mainField = 'name'

// list filter requires an index in filterable column!
const ListFilters = ({ permissions, ...props }) => (
  <Filter>
    <TextInput label="Buscar nombre..." source="name" alwaysOn />
    <TextInput label="Buscar usuario..." source="username" alwaysOn />
    <TextInput label="Buscar email..." source="email" alwaysOn />
  </Filter>
)

const ListActions = () => {
  return <TopToolbar></TopToolbar>
}

export const ContactList = ({ permissions, ...props }) => (
  <List
    title={<ListTitle resourceNamePlural={resourceNamePlural} />}
    filters={permissions && <ListFilters permissions={permissions} />}
    pagination={<ListPagination />}
    perPage={25}
    actions={<ListActions />}
    empty={false}
    {...props}
  >
    <Datagrid rowClick="edit">
      <NumberField source="id" label="ID" textAlign="left" />
      <TextField source="name" label="Nombre" textAlign="left" />
      <TextField source="username" label="Usuario" textAlign="left" />
      <TextField source="email" label="Email" textAlign="left" />
      <ReferenceField source="contactcustomer" label="Cliente de Proactivanet" reference="customers">
        <TextField source="name" />
      </ReferenceField>
      <DateField
        source="created"
        label="Creado"
        textAlign="left"
        locales="es"
        showTime={true}
        options={{
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          timeZone: 'Europe/Madrid',
        }}
      />
      <DateField
        source="updated"
        label="Actualizado"
        textAlign="left"
        emptyText="Nunca"
        locales="es"
        showTime={true}
        options={{
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          timeZone: 'Europe/Madrid',
        }}
      />
    </Datagrid>
  </List>
)

export const ContactEdit = ({ permissions, ...props }) => {
  return (
    <Edit title={<EditTitle resourceName={resourceName} mainField={mainField} />} {...props}>
      <SimpleForm>
        <NumberField source="id" label="ID" textAlign="left" formClassName={classes.inlineBlock} />
        <ReferenceField
          source="contactcustomer"
          label="Cliente de Proactivanet"
          reference="customers"
          formClassName={classes.inlineBlock}
        >
          <TextField source="name" />
        </ReferenceField>
        <DateField
          source="created"
          label="Creado"
          textAlign="left"
          locales="es"
          showTime={true}
          options={{
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            timeZone: 'Europe/Madrid',
          }}
          formClassName={classes.inlineBlockRight}
        />
        <DateField
          source="updated"
          label="Actualizado"
          textAlign="left"
          emptyText="Nunca"
          locales="es"
          showTime={true}
          options={{
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            timeZone: 'Europe/Madrid',
          }}
          formClassName={classes.inlineBlockRight}
        />
        <TextField source="name" label="Nombre" textAlign="left" />
        <TextField source="username" label="Usuario" textAlign="left" />
        <TextField source="email" label="Email" textAlign="left" />
      </SimpleForm>
    </Edit>
  )
}
