// customers.js

import React from 'react'

// react admin
import {
  List,
  Datagrid,
  Button,
  Loading,
  FunctionField,
  BooleanField,
  TextField,
  NumberField,
  DateField,
  Filter,
  TextInput,
  Edit,
  SimpleForm,
  ReferenceManyField,
  ReferenceField,
  TopToolbar,
  usePermissions,
  useRecordContext,
} from 'react-admin'

// custom resource components generic to all resources
import {
  ListTitle,
  ListPagination,
  EditTitle,
  ContainerBox,
  LeftBox,
  RightBox,
  MarginLabeledLeft,
  MarginLabeledRight,
} from 'kevo-react-admin'

// names stuff
import { NameOrigin } from './names.jsx'

// icon for this resource
import AddIcon from '@mui/icons-material/Add'
import AccountBoxIcon from '@mui/icons-material/AccountBox'
export const CustomerIcon = AccountBoxIcon

/*
create table customers(
uuid uuid not null primary key,
cif varchar(255) not null unique,
name varchar(255) not null unique,
created timestamptz not null default now(),
updated timestamptz
);

insert into customers(uuid,cif,name) values('85078fec-ec5a-48fc-a61d-0c9a5d370a78','B75205468','KEVO');
*/

const resource = 'customers'
const resourceName = 'cliente de Proactivanet'
const resourceNamePlural = 'clientes de Proactivanet'
const mainField = 'name'

// list filter requires an index in filterable column!
const ListFilters = ({ permissions }) => (
  <Filter>
    <TextInput label="Buscar nombre..." source="name" parse={(val) => val.toUpperCase()} alwaysOn />
  </Filter>
)

const ListActions = () => {
  return <TopToolbar></TopToolbar>
}

export const CustomerList = () => {
  const { isLoading, permissions } = usePermissions()
  if (isLoading) {
    return <Loading loadingPrimary="Cargando" loadingSecondary="" />
  } else {
    return (
      <List
        title={<ListTitle resourceNamePlural={resourceNamePlural} />}
        filters={permissions && <ListFilters permissions={permissions} />}
        pagination={<ListPagination />}
        perPage={25}
        actions={<ListActions resource={resource} />}
        empty={false}
      >
        <Datagrid rowClick="edit">
          {['ADMIN'].includes(permissions) && <NumberField source="id" label="ID" textAlign="left" />}
          <TextField source="uuid" label="UUID" textAlign="left" />
          <TextField source="name" label="Nombre" textAlign="left" />
          <DateField
            source="created"
            label="Creado"
            textAlign="left"
            locales="es"
            showTime={true}
            options={{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              timeZone: 'Europe/Madrid',
            }}
          />
          <DateField
            source="updated"
            label="Actualizado"
            textAlign="left"
            emptyText="Nunca"
            locales="es"
            showTime={true}
            options={{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              timeZone: 'Europe/Madrid',
            }}
          />
        </Datagrid>
      </List>
    )
  }
}

const RelatedDataFields = ({ permissions }) => {
  const record = useRecordContext()
  return (
    <>
      <ContainerBox>
        <MarginLabeledLeft label="Nombres adicionales">
          <ReferenceManyField
            reference="names"
            target="customers"
            sort={{ field: 'id', order: 'ASC' }}
            perPage={1000000}
            fullWidth={true}
            filter={{ namecustomer: Number.parseInt(record.id) }}
          >
            <Datagrid
              rowClick={(id, basePath, record) =>
                `/names/${id}/edit?source=${JSON.stringify({ namecustomer: Number(record.id) })}`
              }
            >
              {['ADMIN'].includes(permissions) && (
                <ReferenceField source="id" label="ID" reference="names">
                  <TextField source="id" />
                </ReferenceField>
              )}
              <TextField source="name" label="Nombre" />
              <BooleanField source="active" label="Activo" />
              <FunctionField render={NameOrigin} label="Orígen" />
              <DateField
                source="created"
                label="Creado"
                textAlign="left"
                locales="es"
                showTime={true}
                options={{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                  timeZone: 'Europe/Madrid',
                }}
              />
              <DateField
                source="updated"
                label="Actualizado"
                textAlign="left"
                emptyText="Nunca"
                locales="es"
                showTime={true}
                options={{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                  timeZone: 'Europe/Madrid',
                }}
              />
            </Datagrid>
          </ReferenceManyField>
        </MarginLabeledLeft>
      </ContainerBox>
      <ContainerBox>
        <Button
          style={{ color: '#eee', backgroundColor: '#fe1d3c', borderRadius: '8px', padding: '4px' }}
          alignIcon="right"
          children={<AddIcon />}
          label="Añadir nuevo nombre"
          onClick={() =>
            (window.location.href = `/names/create?source=${JSON.stringify({ namecustomer: Number(record.id) })}`)
          }
        />
      </ContainerBox>
      <ContainerBox>
        <MarginLabeledLeft label="Contactos de la empresa">
          <ReferenceManyField
            reference="contacts"
            target="customers"
            sort={{ field: 'id', order: 'ASC' }}
            perPage={1000000}
            fullWidth={true}
            filter={{ contactcustomer: Number.parseInt(record.id) }}
          >
            <Datagrid bulkActionButtons={false}>
              {['ADMIN'].includes(permissions) && (
                <ReferenceField source="id" label="ID" reference="contacts">
                  <TextField source="id" />
                </ReferenceField>
              )}
              <TextField source="name" label="Nombre" textAlign="left" />
              <TextField source="username" label="Usuario" textAlign="left" />
              <TextField source="email" label="Email" textAlign="left" />
              <DateField
                source="created"
                label="Creado"
                textAlign="left"
                locales="es"
                showTime={true}
                options={{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                  timeZone: 'Europe/Madrid',
                }}
              />
              <DateField
                source="updated"
                label="Actualizado"
                textAlign="left"
                emptyText="Nunca"
                locales="es"
                showTime={true}
                options={{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                  timeZone: 'Europe/Madrid',
                }}
              />
            </Datagrid>
          </ReferenceManyField>
        </MarginLabeledLeft>
      </ContainerBox>
    </>
  )
}

export const CustomerEdit = () => {
  const { isLoading, permissions } = usePermissions()
  if (!permissions) {
    return <Loading loadingPrimary="Cargando" loadingSecondary="" />
  } else {
    return (
      <Edit title={<EditTitle resourceName={resourceName} mainField={mainField} />}>
        <SimpleForm>
          <ContainerBox>
            <LeftBox>
              {['ADMIN'].includes(permissions) && (
                <MarginLabeledLeft label="ID">
                  <NumberField source="id" textAlign="left" sx={{ display: 'inline-flex', marginInlineEnd: '2rem' }} />
                </MarginLabeledLeft>
              )}
              <MarginLabeledLeft label="UUID">
                <TextField source="uuid" textAlign="left" sx={{ display: 'inline-flex', marginInlineEnd: '2rem' }} />
              </MarginLabeledLeft>
              <MarginLabeledLeft label="Nombre">
                <TextField source="name" textAlign="left" />
              </MarginLabeledLeft>
            </LeftBox>
            <RightBox>
              <MarginLabeledRight label="Creado">
                <DateField
                  source="created"
                  textAlign="left"
                  locales="es"
                  showTime={true}
                  options={{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    timeZone: 'Europe/Madrid',
                  }}
                  sx={{ display: 'inline-flex', marginInlineEnd: '2rem', float: 'right' }}
                />
              </MarginLabeledRight>
              <MarginLabeledRight label="Actualizado">
                <DateField
                  source="updated"
                  textAlign="left"
                  emptyText="Nunca"
                  locales="es"
                  showTime={true}
                  options={{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    timeZone: 'Europe/Madrid',
                  }}
                  sx={{ display: 'inline-flex', marginInlineEnd: '2rem', float: 'right' }}
                />
              </MarginLabeledRight>
            </RightBox>
          </ContainerBox>
          <RelatedDataFields permissions={permissions} />
        </SimpleForm>
      </Edit>
    )
  }
}
