// events.js

import React from 'react'

// react admin
import {
  List,
  ReferenceField,
  Datagrid,
  TextField,
  NumberField,
  DateField,
  Filter,
  SelectInput,
  TextInput,
  Edit,
  SimpleForm,
  TopToolbar,
  useListContext,
  ExportButton,
  SelectField,
} from 'react-admin'

// generic components
import {
  ListTitle,
  ListPagination,
  EditTitle,
  ContainerBox,
  LeftBox,
  RightBox,
  MarginLabeledLeft,
  MarginLabeledRight,
} from 'kevo-react-admin'

/*
create table events(
id serial not null primary key,
type text not null,
description text not null,
eventcall integer references calls(id) on delete cascade default null ,
created timestamp not null default now()
);
*/

//const resource = 'events'
const resourceName = 'evento'
const resourceNamePlural = 'eventos'
//const masculine = true //${masculine ? "un" : "una"}
const mainField = 'description'

// TODO check events to see if we have stuck businessname or stuck request type !!

const ListFilters = (props) => (
  <Filter {...props}>
    <TextInput label={`Buscar texto...`} source={`${mainField}`} alwaysOn />
    <SelectInput
      sx={{ marginBottom: '0px', minWidth: '226px' }}
      size="small"
      label={`Filtrar tipo...`}
      source="type"
      alwaysOn
      choices={[
        { id: 'CALL_HANGUP_BEFORE_START', name: 'Cuelga al inicio' },
        /*{ id: 'CALL_STUCK_IN_BUSINESSNAME', name: 'Atasco nombre empresa' },*/
        /*{ id: 'CALL_STUCK_IN_REQUESTTYPE', name: 'Atasco tipo solicitud' },*/
        { id: 'CALL_ROUTED_TO_DESTINATION', name: 'Llamada enrutada' },
        { id: 'CALL_ROUTE_NOT_SUCCESSFUL', name: 'Enrutamiento fallido' },
        { id: 'CALL_ROUTE_SUCCESSFUL', name: 'Enrutamiento exitoso' },
        { id: 'CALL_EMAIL_SENT_OK', name: 'Email enviado' },
      ]}
    />
  </Filter>
)
//{ id: 'CALL_EMAIL_NOT_SENT', name: 'Email no enviado' },

const ListActions = () => {
  const { total, isLoading } = useListContext()
  if (isLoading !== false) {
    return (
      <TopToolbar>
        <ExportButton disabled />
      </TopToolbar>
    )
  } else {
    return (
      <TopToolbar>
        <ExportButton disabled={isLoading || total === 0 || true} />
      </TopToolbar>
    )
  }
}

const exporter = (records) => {
  console.log(records)
}

export const EventList = () => {
  return (
    <List
      title={<ListTitle resourceNamePlural={resourceNamePlural} />}
      filters={<ListFilters />}
      empty={false}
      pagination={<ListPagination />}
      perPage={25}
      actions={<ListActions />}
      sort={{ field: 'id', order: 'DESC' }}
      exporter={exporter}
    >
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <NumberField source="id" label="ID" textAlign="left" />
        <SelectField
          source="type"
          label="Tipo"
          textAlign="left"
          choices={[
            { id: 'CALL_HANGUP_BEFORE_START', name: 'Cuelga al inicio' },
            { id: 'CALL_ROUTED_TO_DESTINATION', name: 'Llamada enrutada' },
            { id: 'CALL_ROUTE_NOT_SUCCESSFUL', name: 'Enrutamiento fallido' },
            { id: 'CALL_ROUTE_SUCCESSFUL', name: 'Enrutamiento exitoso' },
            { id: 'CALL_EMAIL_SENT_OK', name: 'Email enviado' },
          ]}
        />
        <TextField source="description" label="Descripción" textAlign="left" />
        <ReferenceField emptyText="No disponible" source="eventcall" label="Llamada" reference="calls">
          <TextField source="sid" />
        </ReferenceField>
        <DateField
          source="created"
          label="Creado"
          textAlign="left"
          locales="es"
          showTime={true}
          options={{
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            timeZone: 'Europe/Madrid',
          }}
        />
      </Datagrid>
    </List>
  )
}

export const EventEdit = () => {
  //const permissions = usePermissions()
  return (
    <Edit title={<EditTitle resourceName={resourceName} mainField={mainField} />}>
      <SimpleForm>
        <ContainerBox>
          <LeftBox>
            <MarginLabeledLeft label="ID">
              <NumberField source="id" textAlign="left" />
            </MarginLabeledLeft>
            <MarginLabeledLeft label="Tipo">
              <SelectField
                source="type"
                textAlign="left"
                choices={[
                  { id: 'CALL_HANGUP_BEFORE_START', name: 'Cuelga al inicio' },
                  { id: 'CALL_ROUTED_TO_DESTINATION', name: 'Llamada enrutada' },
                  { id: 'CALL_ROUTE_NOT_SUCCESSFUL', name: 'Enrutamiento fallido' },
                  { id: 'CALL_ROUTE_SUCCESSFUL', name: 'Enrutamiento exitoso' },
                  { id: 'CALL_EMAIL_SENT_OK', name: 'Email enviado' },
                ]}
              />
            </MarginLabeledLeft>
            <MarginLabeledLeft label="Creado">
              <DateField
                source="created"
                textAlign="left"
                locales="es"
                showTime={true}
                options={{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                  timeZone: 'Europe/Madrid',
                }}
              />
            </MarginLabeledLeft>
          </LeftBox>
          <RightBox>
            <MarginLabeledRight label="Llamada">
              <ReferenceField emptyText="No disponible" source="eventcall" reference="calls">
                <TextField source="sid" />
              </ReferenceField>
            </MarginLabeledRight>
          </RightBox>
        </ContainerBox>
        <MarginLabeledLeft label="Descripción">
          <TextField source="description" textAlign="left" />
        </MarginLabeledLeft>
      </SimpleForm>
    </Edit>
  )
}
