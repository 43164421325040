// App.js

import React from 'react'

// react admin
import { Admin, Resource, CustomRoutes, Loading, Notification } from 'react-admin'

// resources & common imports
import { Dashboard, authProvider, Login, Layout, i18nProvider, history, mainTheme } from 'kevo-react-admin'

// resources
import { UserList, UserEdit, UserCreate } from './resources/users.jsx'
import { TranscriptionList, TranscriptionEdit } from './resources/transcriptions.jsx'
import { EventList, EventEdit } from './resources/events.jsx'
import { CallList, CallEdit } from './resources/calls.jsx'

// mui icons
import PersonIcon from '@mui/icons-material/Person.js'
import PhoneIcon from '@mui/icons-material/Phone.js'
import CommentIcon from '@mui/icons-material/Comment.js'
import VerticalSplitIcon from '@mui/icons-material/VerticalSplit.js'

// proactivanet data
import { CustomerList, CustomerEdit, CustomerIcon } from './resources/customers.jsx'
import { NameList, NameEdit, NameCreate, NameIcon } from './resources/names.jsx'
import { ContactList, ContactEdit, ContactIcon } from './resources/contacts.jsx'

// app custom routes
import { Route } from 'react-router-dom'
import { About, Configuration, Statistics } from 'kevo-react-admin'

import CatchAll from './Components/CatchAll.jsx'

const CustomNotification = (props) => <Notification {...props} autoHideDuration={50000} />

const App = ({ dataProvider }) => {
  if (dataProvider) {
    return (
      <Admin
        disableTelemetry
        style={{ flexGrow: 1 }}
        history={history}
        title={`${import.meta.env.VITE_SITE_NAME} - Administrador`}
        i18nProvider={i18nProvider}
        loginPage={Login}
        catchAll={CatchAll}
        notification={CustomNotification}
        layout={Layout}
        dashboard={Dashboard}
        theme={mainTheme}
        authProvider={authProvider}
        dataProvider={dataProvider}
      >
        <CustomRoutes>
          <Route path="/about" element={<About />} />
          <Route path="/configuration" element={<Configuration />} />
          <Route path="/statistics" element={<Statistics />} />
        </CustomRoutes>
        {(permissions) => [
          ['ADMIN'].includes(permissions) ? (
            <Resource
              name="users"
              options={{ label: 'Usuarios' }}
              icon={PersonIcon}
              list={UserList}
              show={null}
              edit={UserEdit}
              create={UserCreate}
            />
          ) : (
            <Resource
              name="users"
              options={{ label: 'Usuarios' }}
              icon={PersonIcon}
              list={null}
              show={null}
              edit={null}
              create={null}
            />
          ),
          <Resource
            name="customers"
            options={{ label: 'Clientes de Proactivanet' }}
            icon={CustomerIcon}
            list={CustomerList}
            show={null}
            edit={CustomerEdit}
            create={null}
          />,
          <Resource
            name="names"
            options={{ label: 'Nombres de clientes de Proactivanet' }}
            icon={NameIcon}
            list={['ADMIN'].includes(permissions) ? NameList : null}
            show={null}
            edit={NameEdit}
            create={NameCreate}
          />,
          <Resource
            name="contacts"
            options={{ label: 'Contactos de clientes de Proactivanet' }}
            icon={ContactIcon}
            list={['ADMIN'].includes(permissions) ? ContactList : null}
            show={null}
            edit={ContactEdit}
            create={null}
          />,
          <Resource
            name="calls"
            options={{ label: 'Llamadas' }}
            icon={PhoneIcon}
            list={CallList}
            show={null}
            edit={CallEdit}
            create={null}
          />,
          ['ADMIN'].includes(permissions) ? (
            <Resource
              name="transcriptions"
              options={{ label: 'Transcripciones' }}
              icon={CommentIcon}
              list={TranscriptionList}
              show={null}
              edit={TranscriptionEdit}
              create={null}
            />
          ) : (
            <Resource
              name="transcriptions"
              options={{ label: 'Transcripciones' }}
              icon={CommentIcon}
              list={null}
              show={null}
              edit={null}
              create={null}
            />
          ),
          <Resource
            name="events"
            options={{ label: 'Eventos' }}
            icon={VerticalSplitIcon}
            list={EventList}
            show={null}
            edit={EventEdit}
            create={null}
          />,
        ]}
      </Admin>
    )
  } else {
    return <Loading />
  }
}

export default App
